import styled from 'styled-components'
import GatsbyImage from 'gatsby-image'
import { Media, mbSize } from '@renderbus/common/theme'

export const PageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: max-content;
  padding-bottom: 41px;
  ${Media.lessThan(Media.small)} {
    padding-bottom: ${mbSize(46)};
  }
`

export const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const BgImage = styled(GatsbyImage)`
  position: absolute !important;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
`

export const MidYearRechargeTime = styled.div`
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  line-height: 22px;
  display: flex;
  z-index: 2;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(24)};
    line-height: ${mbSize(22)};
  }
`

export const ContentImage = styled(GatsbyImage)`
  width: 577px;
  height: 282px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(577)};
    height: ${mbSize(282)};
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 360px;
  height: 44px;
  border-radius: 22px;
  border: 2px solid #fdfeff;
  margin: 126px 0 0 0;
  font-size: 22px;
  color: #fdfeff;
  line-height: 22px;
  letter-spacing: 2px;
  .diamond {
    width: 20px;
    height: 19px;
    margin-right: 13px;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(403)};
    height: ${mbSize(46)};
    font-size: ${mbSize(24)};
    color: #fdfeff;
    margin: ${mbSize(200)} 0 0 0;
    letter-spacing: ${mbSize(2)};
    border-radius: ${mbSize(22)};
    border: ${mbSize(2)} solid #fdfeff;
    .diamond {
      width: ${mbSize(20)};
      height: ${mbSize(19)};
      margin-right: ${mbSize(7)};
    }
  }
`

export const ToDetailBtn = styled.div`
  position: relative;
  width: 240px;
  height: 100px;
  margin-top: -59px;
  .hover {
    display: none;
  }
  &:hover {
    .hover {
      display: block;
    }
    .normal {
      display: none;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(280)};
    height: ${mbSize(120)};
    margin-top: ${mbSize(-63)};
  }
`

export const ContentWrapper = styled.div`
  margin-top: 376px;
  width: 1400px;
  height: 1150px;
  background: linear-gradient(220deg, #99c7fd 0%, #d8eaff 100%);
  border-radius: 50px;
  border: 2px solid #ffffff;
  ${Media.lessThan(Media.small)} {
    margin-top: ${mbSize(420)};
    width: ${mbSize(710)};
    height: ${mbSize(1579)};
    border-radius: ${mbSize(30)};
  }
`
export const CardWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 39px 36px 46px;
  height: 380px;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    align-items: center;
    height: max-content;
    margin: ${mbSize(29)} auto 0;
  }
`

export const CardLeft = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  color: #2b4771;
  height: max-content;
  * {
    line-height: 1;
  }
  .title {
    font-weight: 500;
    font-size: 28px;
    margin-bottom: 20px;
  }
  .refund {
    display: flex;
    align-items: flex-end;
    font-size: 70px;
    font-weight: 900;
    background: linear-gradient(0deg, #486186 0%, #5e80a8 99.5849609375%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    .get {
      margin: -7px 0;
      height: max-content
      font-family: Arial;
    }
    .unit {
      font-weight: 500;
      margin-left: 10px;
      font-size: 28px;
    }
  }
  ${Media.lessThan(Media.small)} {
    .title {
      font-size: ${mbSize(28)};
      margin-bottom: ${mbSize(20)};
    }
    .refund {
      width: max-content;
      height: max-content;
      font-size: ${mbSize(70)};
      .get {
        margin: ${mbSize(-8)} 0;
      }
      .unit {
        margin-left: ${mbSize(10)};
        font-size: ${mbSize(28)};
      }
    }
  }
`

export const Card = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  width: 648px;
  height: 170px;
  padding: 0 33px 0 50px;
  .card {
    position: absolute !important;
    width: 100%;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
  }
  &.active {
    ${CardLeft} {
      color: #1f6dcc;
      .refund {
        background: #1f6dcc;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(648)};
    height: ${mbSize(170)};
    padding: 0 ${mbSize(23)} 0 ${mbSize(50)};
    margin-bottom: ${mbSize(37)};
  }
`

export const CardRight = styled.div`
  display: flex;
  position: relative;
  width: 238px;
  justify-content: space-between;
  font-size: 18px;
  color: #437099;
  &::before {
    position: absolute;
    content: '';
    width: 13px;
    left: calc(50% - 13px);
    top: 50%;
    transform: translate(-50%, -50%);
    border-top: 2px solid #437099;
  }
  &::after {
    position: absolute;
    content: '';
    height: 13px;
    left: calc(50% - 13px);
    top: 50%;
    transform: translate(-50%, -50%);
    border-left: 2px solid #437099;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(227)};
    font-size: ${mbSize(20)};
    &::before {
      width: ${mbSize(13)};
      left: calc(50% - ${mbSize(13)});
    }
    &::after {
      height: ${mbSize(13)};
      left: calc(50% - ${mbSize(13)});
    }
  }
`

export const RechargeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 34px;
  color: #e3fffa;
  .title {
    font-weight: bold;
    font-size: 24px;
    color: #1f6dcc;
  }
  .input-wrapper {
    display: flex;
    align-items: center;
    margin-top: 17px;
  }
  .amount {
    display: flex;
    align-items: center;
    padding-left: 20px;
    width: 296px;
    height: 76px;
    background: #1f6dcc;
    border-radius: 10px;
    font-size: 24px;
  }
  .gift {
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 363px;
    height: 48px;
    background: #1f6dcc;
    border-radius: 10px;
    font-size: 18px;
    color: #fefefe;
  }
  ${Media.lessThan(Media.small)} {
    margin: 0 0 0 ${mbSize(32)};
    .title {
      font-size: ${mbSize(30)};
    }
    .input-wrapper {
      margin-top: ${mbSize(19)};
    }
    .amount {
      padding-left: ${mbSize(20)};
      width: ${mbSize(280)};
      height: ${mbSize(76)};
      border-radius: ${mbSize(10)};
      font-size: ${mbSize(30)};
    }
    .gift {
      margin-left: ${mbSize(15)};
      width: ${mbSize(350)};
      height: ${mbSize(48)};
      background: #3e7ecf;
      border-radius: ${mbSize(10)};
      font-size: ${mbSize(18)};
    }
  }
`

export const RechargeButton = styled.a`
  width: 360px;
  height: 74px;
  align-self: center;
  cursor: pointer;
  margin: 12px 0 63px -34px;
  .recharge-hover {
    display: none;
  }
  &:hover {
    .recharge-hover {
      display: block;
    }
    .recharge-normal {
      display: none;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(360)};
    height: ${mbSize(73)};
    margin: ${mbSize(11)} 0 ${mbSize(83)} ${mbSize(-32)};
  }
`

export const RulesWrapper = styled.div`
  margin: 0 auto;
  width: 1285px;
  height: 460px;
  padding: 39px 27px 61px;
  background: linear-gradient(45deg, rgba(245, 250, 255, 0.6) 0%, rgba(225, 248, 255, 0.3) 100%);
  border-radius: 20px;
  border: 2px solid rgba(225, 248, 255, 0.3);
  .title {
    font-size: 24px;
    color: #1f6dcc;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .rule {
    display: flex;
    align-items: flex-start;
    max-width: 870px;
    line-height: 42px;
    font-size: 22px;
    color: #1f6dcc;
  }
  .star {
    min-width: 19px;
    height: 19px;
    margin: 10px 4px 0 0;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(672)};
    height: ${mbSize(532)};
    padding: ${mbSize(39)} ${mbSize(21)} ${mbSize(47)};
    border-radius: ${mbSize(20)};
    .title {
      font-size: ${mbSize(24)};
      margin-bottom: ${mbSize(10)};
    }
    .rule {
      max-width: unset;
      line-height: ${mbSize(42)};
      font-size: ${mbSize(22)};
    }
    .star {
      min-width: ${mbSize(21)};
      height: ${mbSize(21)};
      margin: ${mbSize(10)} ${mbSize(6)} 0 0;
    }
  }
`
