import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { isTest } from '@renderbus/common/service'
import { SEO } from '@renderbus/common/components'
import {
  BannerWrapper,
  BgImage,
  Card,
  CardLeft,
  CardRight,
  CardWrapper,
  ContentImage,
  ContentWrapper,
  MidYearRechargeTime,
  PageContainer,
  RechargeButton,
  RechargeWrapper,
  RulesWrapper,
  TitleWrapper,
} from './nzjh-czhd.atom'
import Layout from '../molecules/layout'

const giftList = [
  {
    recharge: 300,
    get: 400,
    balance: 300,
    common: 100,
  },
  {
    recharge: 600,
    get: 850,
    balance: 600,
    common: 250,
  },
  {
    recharge: 2000,
    get: 2900,
    balance: 2000,
    common: 900,
  },
  {
    recharge: 5000,
    get: 7500,
    balance: 5000,
    common: 2500,
  },
]

const ruleList = [
  '活动仅限Renderbus效果图用户参与；',
  '活动期间单笔充值金额：满300元赠送100元通用券，满600元赠送250元通用券，满2000元赠送900元通用券，满5000元赠送2500元通用券；',
  '每个用户仅可以参与一次充值福利，举例：如选择“充值300元得赠送100元通用券”，则不能再参加本活动其他门槛的充值福利；',
  '赠送的通用渲染券为即时到账，请及时查看账户余额，有问题联系客服。通用券有限期为6个月（以充值时间起算）；',
  '本活动最终解析权利归深圳市瑞云科技股份有限公司所有。',
]
class MidYearRecharge2022Xgt extends React.PureComponent {
  state = {
    activeIndex: 3,
  }
  handleClick(activeIndex = 3) {
    this.setState({
      activeIndex,
    })
  }
  render() {
    const {
      location,
      pageContext: { topThreeShare },
    } = this.props
    return (
      <StaticQuery
        query={graphql`
          query {
            bannerPage: file(
              relativePath: { regex: "/xgt-mid-year-recharge/2024/bg-page-adjust.png/" }
            ) {
              ...fluidImage
            }
            bannerPageMb: file(
              relativePath: { regex: "/xgt-mid-year-recharge/2024/bg-page-banner-mb.png/" }
            ) {
              ...fluidImage
            }
            bannerPageLarge: file(
              relativePath: { regex: "/xgt-mid-year-recharge/2024/bg-page-large.png/" }
            ) {
              ...fluidImage
            }
            content: file(relativePath: { regex: "/xgt-mid-year-recharge/2024/content.png/" }) {
              ...fluidImage
            }
            diamond: file(relativePath: { regex: "/xgt-mid-year-recharge/2024/diamond.png/" }) {
              ...fluidImage
            }
            button: file(
              relativePath: { regex: "/xgt-mid-year-recharge/2024/banner-button.png/" }
            ) {
              ...fluidImage
            }
            buttonHover: file(
              relativePath: { regex: "/xgt-mid-year-recharge/2024/banner-button-hover.png/" }
            ) {
              ...fluidImage
            }
            card: file(relativePath: { regex: "/xgt-mid-year-recharge/2024/card.png/" }) {
              ...fluidImage
            }
            cardNormal: file(
              relativePath: { regex: "/xgt-mid-year-recharge/2024/card-normal.png/" }
            ) {
              ...fluidImage
            }
            star: file(relativePath: { regex: "/xgt-mid-year-recharge/2024/star.png/" }) {
              ...fluidImage
            }
          }
        `}
        render={({
          bannerPage,
          bannerPageMb,
          bannerPageLarge,
          content,
          diamond,
          button,
          buttonHover,
          card,
          star,
          cardNormal,
        }) => (
          <Layout location={location} topThreeShare={topThreeShare}>
            <SEO
              title='「瑞云效果图渲染」年中盛典,充值大回馈活动-瑞云渲染'
              keywords='瑞云效果图渲染年中充值,瑞云效果图充值大回馈,效果图年中盛典充值'
              description='Renderbus瑞云效果图渲染2024年中钜惠充值活动正式来袭，无论新老用户登陆效果图账户进行充值，充多少送多少，最高可送2500元通用渲染劵，立即加入瑞云效果图年中盛典充值活动吧。'
              sharePostSlug='nzjh-czhd.html'
            />
            <PageContainer>
              <BgImage
                fluid={[
                  bannerPage.childImageSharp.fluid,
                  {
                    media: '(max-width: 780px)',
                    ...bannerPageMb.childImageSharp.fluid,
                  },
                  {
                    media: '(min-width: 1940px)',
                    ...bannerPageLarge.childImageSharp.fluid,
                  },
                ]}
              />
              <BannerWrapper>
                <TitleWrapper>
                  <GatsbyImage fluid={diamond.childImageSharp.fluid} className='diamond' />
                  瑞云效果图充值福利享不停
                </TitleWrapper>
                <ContentImage fluid={[content.childImageSharp.fluid]} />
                <MidYearRechargeTime>{`活动时间：2024年6月7日-7月7日`}</MidYearRechargeTime>
              </BannerWrapper>
              <ContentWrapper>
                <CardWrapper>
                  {giftList.map((item, index) => (
                    <Card
                      key={index}
                      className={this.state.activeIndex === index ? 'active' : ''}
                      onClick={() => {
                        this.handleClick(index)
                      }}
                    >
                      <GatsbyImage
                        fluid={
                          this.state.activeIndex === index
                            ? card.childImageSharp.fluid
                            : cardNormal.childImageSharp.fluid
                        }
                        className='card'
                      />
                      <CardLeft>
                        <span className='title'>{`充${item.recharge}元得`}</span>
                        <span className='refund'>
                          <span className='get'>{item.get}</span>
                          <span className='unit'>元</span>
                        </span>
                      </CardLeft>
                      <CardRight>
                        <div>
                          <div>余额到账</div>
                          <div>{item.balance}元</div>
                        </div>
                        <div>
                          <div>赠送通用券</div>
                          <div>{item.common}元</div>
                        </div>
                      </CardRight>
                    </Card>
                  ))}
                </CardWrapper>
                <RechargeWrapper>
                  <RechargeButton
                    href={`https://${
                      isTest() ? 'account-pre' : 'account'
                    }.renderbus.com/center/user/topUp?chooseCard=${
                      giftList[this.state.activeIndex].recharge
                    }`}
                    target='_blank'
                  >
                    <GatsbyImage fluid={button.childImageSharp.fluid} className='recharge-normal' />
                    <GatsbyImage
                      fluid={buttonHover.childImageSharp.fluid}
                      className='recharge-hover'
                    />
                  </RechargeButton>
                </RechargeWrapper>
                <RulesWrapper>
                  <div className='title'>活动规则</div>
                  {ruleList.map((item, index) => (
                    <div className='rule' key={index}>
                      <GatsbyImage fluid={star.childImageSharp.fluid} className='star' />
                      <span>{item}</span>
                    </div>
                  ))}
                </RulesWrapper>
              </ContentWrapper>
            </PageContainer>
          </Layout>
        )}
      />
    )
  }
}

export default MidYearRecharge2022Xgt
